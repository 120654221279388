import React, { useState, useEffect, useRef } from "react"
import styled from "styled-components"
import { font, color, ease, device } from "../../layout/global"
import { CountryDropdown } from "react-country-region-selector"
// import { useBreakpoint } from 'gatsby-plugin-breakpoints'
// import Slider from "react-slick"
import SubmitButton from "../SubmitButton"
import TermsCheckbox from "../TermsCheckbox"
import fbTrack from "../../hooks/fbTrack"

import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

if (typeof window !== `undefined`) {
  gsap.registerPlugin(ScrollTrigger)
  gsap.core.globals("ScrollTrigger", ScrollTrigger)
}

const axios = require("axios")

const FormContact = ({ id, info, lang, ko }) => {
  const [country, setCountry] = useState("")

  const [successful, setSuccessful] = useState(false)
  const [hasError, setHasError] = useState(false)
  const [focused, setFocused] = useState(false)

  const validityString = useRef(null)
  const validityStringEmail = useRef(null)

  const triggerRef = useRef(null),
    input1Ref = useRef(null),
    input2Ref = useRef(null),
    input3Ref = useRef(null),
    input4Ref = useRef(null),
    input5Ref = useRef(null),
    input6Ref = useRef(null),
    input7Ref = useRef(null),
    inputField1 = useRef(null),
    inputField2 = useRef(null),
    inputField3 = useRef(null),
    inputField4 = useRef(null),
    inputField5 = useRef(null)

  function doSubmit(e) {
    e.preventDefault()
    var formData = new FormData()
    formData.append("name", document.querySelector("#fc-name").value)
    formData.append("email", document.querySelector("#fc-email").value)
    formData.append("tel", document.querySelector("#fc-tel").value)
    formData.append("country", country)
    formData.append(
      "postalcode",
      document.querySelector("#fc-postal-code").value
    )
    formData.append("message", document.querySelector("#fs-message").value)
    formData.append("option", 4)
    document.querySelector("#terms-01").checked &&
      formData.append("terms01", document.querySelector("#terms-01").value)
    document.querySelector("#terms-02").checked &&
      formData.append("terms02", document.querySelector("#terms-02").value)

    axios
      .post("https://pasteldenata.ramalhos.com/form-contact.php", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data; charset=UTF-8",
        },
      })
      .then(res => {
        console.log(res)
        if (res.data === "success") {
          onSuccess()
          typeof window !== "undefined" &&
            window.gtag("event", "Submit", {
              event_category: "Submit Button",
              event_label: "Envio de formulário - Pastel de Nata",
            })
          fbTrack("trackCustom", "Formulário - Landing")
        } else {
          onError()
        }
      })
      .catch(err => console.log(err))
  }

  useEffect(() => {
    let I1 = input1Ref.current,
      I2 = input2Ref.current,
      I3 = input3Ref.current,
      I4 = input4Ref.current,
      I5 = input5Ref.current,
      I6 = input6Ref.current,
      I7 = input7Ref.current

    gsap
      .timeline({
        paused: true,
        scrollTrigger: {
          anticipatePin: 1,
          trigger: triggerRef.current,
          toggleActions: "play none none none",
          start: "center 80%",
        },
      })
      .fromTo(
        [I1, I2, I3, I4, I5, I6, I7],
        { opacity: 0, y: 25 },
        { opacity: 1, y: 0, duration: 0.5, stagger: 0.15 }
      )
  }, [])

  useEffect(() => {
    if (document.getElementById("contact-form")) {
      document.getElementById("contact-form").style.opacity = "1"
    }
    switch (lang) {
      case "pt":
        validityString.current = `Campo obrigatório.`
        validityStringEmail.current = `O e-mail deve estar no seguinte formato: xxx@yyy.zzz`
        break
      case "en":
        validityString.current = `Mandatory field.`
        validityStringEmail.current = `The e-mail must be in the following format: xxx@yyy.zzz`
        break
      case "es":
        validityString.current = `Campo obligatorio.`
        validityStringEmail.current = `El correo electrónico debe tener el siguiente formato: xxx@yyy.zzz`
        break
      case "fr":
        validityString.current = `Champ obligatoire.`
        validityStringEmail.current = `L'e-mail doit être au format suivant : xxx@yyy.zzz`
        break
      default:
        validityString.current = `Campo obrigatório.`
        validityStringEmail.current = `O e-mail deve estar no seguinte formato: xxx@yyy.zzz`
        break
    }
    inputField1.current.setCustomValidity(validityString.current)
    inputField2.current.setCustomValidity(validityString.current)
    inputField3.current.setCustomValidity(validityString.current)
    inputField5.current.setCustomValidity(validityString.current)
  }, [lang])

  useEffect(() => {
    if (focused) {
      !inputField1.current.validity.valueMissing
        ? inputField1.current.setCustomValidity("")
        : inputField1.current.setCustomValidity(validityString.current)

      if (inputField2.current.validity.typeMismatch) {
        inputField2.current.setCustomValidity(validityStringEmail.current)
      } else if (inputField2.current.validity.valueMissing) {
        inputField2.current.setCustomValidity(validityString.current)
      } else {
        inputField2.current.setCustomValidity("")
      }

      !inputField3.current.validity.valueMissing
        ? inputField3.current.setCustomValidity("")
        : inputField3.current.setCustomValidity(validityString.current)
      !inputField5.current.validity.valueMissing
        ? inputField5.current.setCustomValidity("")
        : inputField5.current.setCustomValidity(validityString.current)
      setFocused(false)
    }
  }, [focused])

  const onSuccess = () => {
    document.getElementById("contact-form").style.opacity = "0"
    setTimeout(function() {
      setSuccessful(true)
      setTimeout(function() {
        document.getElementById("successful").style.opacity = "1"
        setTimeout(function() {
          document.getElementById("successful").style.opacity = "0"
          setTimeout(function() {
            setSuccessful(false)
            setTimeout(function() {
              document.getElementById("contact-form").style.opacity = "1"
            }, 150)
          }, 300)
        }, 3500)
      }, 150)
    }, 300)
  }
  const onError = () => {
    document.getElementById("contact-form").style.opacity = "0"
    setTimeout(function() {
      setHasError(true)
      setTimeout(function() {
        document.getElementById("error").style.opacity = "1"
        setTimeout(function() {
          document.getElementById("error").style.opacity = "0"
          setTimeout(function() {
            setHasError(false)
            setTimeout(function() {
              document.getElementById("contact-form").style.opacity = "1"
            }, 150)
          }, 300)
        }, 3500)
      }, 150)
    }, 300)
  }
  const doFocus = () => {
    setFocused(true)
  }
  const selectCountry = val => {
    setCountry(val)
  }

  return (
    <Wrapper id={id} ref={triggerRef} ko={ko}>
      <div className="container">
        {hasError ? (
          <div id="error">
            <h2>{info.error1}</h2>
            <p>{info.error2}</p>
          </div>
        ) : !successful ? (
          <form
            id="contact-form"
            onSubmit={doSubmit}
            method="POST"
            autoComplete="off"
          >
            <div className="input-wrapper" id="fcw-name" ref={input1Ref}>
              <input
                ref={inputField1}
                onChange={doFocus}
                className="fc-input invalid"
                id="fc-name"
                name="name"
                type="text"
                placeholder={info.field1}
                required
              ></input>
              <label htmlFor="fc-name" className="label">
                {info.field1} <span style={{ color: "red" }}>*</span>
              </label>
            </div>
            <div className="input-wrapper" id="fcw-email" ref={input2Ref}>
              <input
                ref={inputField2}
                onChange={doFocus}
                className="fc-input"
                id="fc-email"
                name="email"
                type="email"
                placeholder="Email"
                required
              ></input>
              <label htmlFor="fc-email" className="label">
                {info.field2} <span style={{ color: "red" }}>*</span>
              </label>
            </div>
            <div className="input-wrapper" id="fcw-tel" ref={input3Ref}>
              <input
                ref={inputField3}
                onChange={doFocus}
                className="fc-input"
                id="fc-tel"
                name="tel"
                type="text"
                placeholder="Tel"
                pattern="[0-9]*"
                required
              ></input>
              <label htmlFor="fc-tel" className="label">
                {info.field3} <span style={{ color: "red" }}>*</span>
              </label>
            </div>
            <div className="input-wrapper" id="fcw-country" ref={input4Ref}>
              <CountryDropdown
                id="fc-country"
                name="country"
                defaultOptionLabel=""
                required
                value={country}
                onChange={val => selectCountry(val)}
                ref={inputField4}
                priorityOptions={["KR"]}
              />
              <label htmlFor="fc-country" className="label">
                {info.field4} <span style={{ color: "red" }}>*</span>
              </label>
            </div>
            <div className="input-wrapper" id="fcw-postal-code" ref={input5Ref}>
              <input
                ref={inputField5}
                onChange={doFocus}
                className="fc-input"
                id="fc-postal-code"
                name="postal-code"
                type="text"
                placeholder="Código Postal"
                required
              ></input>
              <label htmlFor="fc-postal-code" className="label">
                {info.field5} <span style={{ color: "red" }}>*</span>
              </label>
            </div>
            <div className="select-wrapper" ref={input6Ref}>
              <h1>{info.text1}</h1>
            </div>
            <div id="fcw-secondary" ref={input7Ref}>
              <div className="input-wrapper-message">
                <textarea
                  className="fs-message"
                  id="fs-message"
                  placeholder={info.field6}
                ></textarea>
                <label
                  htmlFor="fs-message"
                  className="category-label message-label"
                >
                  {info.field6}
                </label>
                <TermsCheckbox
                  hasTerms
                  hasShare
                  text1={info.button1}
                  text2={info.button2}
                  text3={info.button3}
                  language={lang}
                />
              </div>
              <div className="submit-wrapper">
                <SubmitButton text={info.submit} />
              </div>
            </div>
          </form>
        ) : (
          <div id="successful">
            <span></span>
            <h2>{info.confirmation1}</h2>
            <p>{info.confirmation2}</p>
          </div>
        )}
      </div>
    </Wrapper>
  )
}

export default FormContact

const Wrapper = styled.section`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-color: #fff;
  z-index: 2;
  position: relative;

  @media ${device.mobileP} {
    padding: 5rem 0;
  }

  @media ${device.tabletP} {
    padding: 15rem 0;
  }

  #successful {
    opacity: 0;
    transition: all 0.3s ${ease.out};
    text-align: center;
    display: grid;
    grid-template-rows: auto auto 1fr;
    justify-content: center;
    span {
      display: block;
      width: 7px;
      height: 12px;
      border: solid ${color.red};
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
      justify-self: center;
      margin-bottom: 25px;
    }
    h2 {
      ${props => (props.ko ? font.Noto400 : font.financierBoldN)};
      font-size: 2.5rem;
      letter-spacing: -0.1px;
      color: ${color.greyDark};
    }
    p {
      ${props => (props.ko ? font.Noto700 : font.robotoMedium)};
      font-size: 1.5rem;
      text-transform: uppercase;
      color: ${color.grey};
    }
  }

  #error {
    opacity: 0;
    transition: all 0.3s ${ease.out};
    text-align: center;
    display: grid;
    grid-template-rows: auto auto auto;
    justify-content: center;
    h2 {
      ${props => (props.ko ? font.Noto400 : font.financierBoldN)};
      font-size: 1.4rem;
      letter-spacing: -0.1px;
      color: ${color.greyDark};
    }
    p {
      ${props => (props.ko ? font.Noto700 : font.robotoMedium)};
      text-transform: uppercase;
      color: ${color.grey};
    }
  }

  .container {
    @media ${device.tabletP} {
      width: 90%;
      max-width: 900px;
    }
    min-height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  form {
    display: grid;
    width: 100%;
    opacity: 0;
    transition: all 0.3s ${ease.out};

    @media ${device.mobileP} {
      grid-template-areas:
        "name"
        "email"
        "tel"
        "country"
        "postalcode"
        "select"
        "secondary";
    }

    @media ${device.tabletP} {
      grid-template-areas:
        "name name name"
        "email email tel"
        "country country postalcode"
        ". select ."
        "secondary secondary secondary";
    }
  }

  #fcw-name {
    grid-area: name;
  }
  #fcw-email {
    grid-area: email;
  }
  #fcw-tel {
    grid-area: tel;
  }
  #fcw-country {
    grid-area: country;
  }
  #fcw-postal-code {
    grid-area: postalcode;
  }
  #fcw-secondary {
    grid-area: secondary;
  }

  .input-wrapper {
    position: relative;
    margin: 10px;
  }

  .fc-input,
  #fc-country {
    width: 100%;
    border: 0;
    outline: 0;
    min-height: 44px;
    background-color: ${color.greyOpacity};
    color: ${color.greyDark};
    ${props => (props.ko ? font.Noto700 : font.robotoMedium)};
    font-size: 12px;
    letter-spacing: 0.8px;
    text-transform: uppercase;
    padding: 20px 12px 6px;

    &::placeholder {
      color: transparent;
      user-select: none;
    }
  }
  .fc-input {
    &:focus,
    &:not(:placeholder-shown) {
      & + .label {
        transform: translateY(-3px) scale(0.75);
        opacity: 0.75;
      }
    }
  }
  #fc-country:valid ~ .label {
    transform: translateY(-3px) scale(0.75);
    opacity: 0.75;
  }

  .label {
    position: absolute;
    left: 12px;
    top: 6px;
    color: ${color.greyDark};
    ${props => (props.ko ? font.Noto700 : font.robotoMedium)};
    font-size: 12px;
    letter-spacing: 0.8px;
    text-transform: uppercase;
    transform-origin: left;
    transform: translateY(8px) scale(1);
    pointer-events: none;
    transition: all 0.15s ${ease.out};
  }

  .select-wrapper {
    margin-top: 50px;
    display: grid;
    grid-area: select;
    grid-template-rows: repeat(2, auto);
  }

  .select-wrapper h1 {
    text-align: center;
    ${props => (props.ko ? font.Noto400 : font.financierRegularM)};

    font-size: 2.4rem;
    color: ${color.greyDark};
  }

  #fc-country {
    -webkit-appearance: none;
    border-radius: 0px;
    background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHBvbHlnb24gY2xhc3M9ImNscy0yIiBwb2ludHM9IjEuNDEgNC42NyAyLjQ4IDMuMTggMy41NCA0LjY3IDEuNDEgNC42NyIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIzLjU0IDUuMzMgMi40OCA2LjgyIDEuNDEgNS4zMyAzLjU0IDUuMzMiLz48L3N2Zz4=)
      no-repeat 95% 50%;
    background-color: ${color.greyOpacity};
  }

  #secondary-choice {
    -webkit-appearance: none;
    border-radius: 0px;
    background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHBvbHlnb24gY2xhc3M9ImNscy0yIiBwb2ludHM9IjEuNDEgNC42NyAyLjQ4IDMuMTggMy41NCA0LjY3IDEuNDEgNC42NyIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIzLjU0IDUuMzMgMi40OCA2LjgyIDEuNDEgNS4zMyAzLjU0IDUuMzMiLz48L3N2Zz4=)
      no-repeat 95% 50%;
  }

  .select-wrapper select {
    border: 0;
    outline: 0;
    height: 44px;
    border-bottom: 1px solid ${color.greyDarker};
    ${props => (props.ko ? font.Noto700 : font.robotoMedium)};
    font-size: 1.4rem;
    color: ${color.grey};
    -webkit-appearance: none;
  }

  .select-wrapper option {
    margin: 5px 0;
  }

  #fcw-secondary {
    @media ${device.mobileP} {
      margin: 60px 0;
    }

    @media ${device.tabletP} {
      margin: 50px 25px 0;
    }
  }

  .form-secondary h2 {
    ${props => (props.ko ? font.Noto700 : font.robotoMedium)};
    text-transform: uppercase;
    letter-spacing: 1px;

    @media ${device.mobileP} {
      margin: 0 10px;
    }

    @media ${device.tabletP} {
      margin: 0;
    }
  }

  .category-picker {
    display: grid;

    @media ${device.mobileP} {
      grid-template-columns: repeat(2, 1fr);
    }

    @media ${device.tabletP} {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(2, 1fr);
      margin: 10px 40px;
    }
  }

  .category-picker-item.double {
    grid-column-start: span 2;
  }

  .category-picker-item {
    position: relative;
    margin: 5px;
  }

  .category-checkbox {
    opacity: 0;
    background: transparent;
    outline: 0;
    border: none;
    width: 0;
    height: 0;

    &::placeholder {
      color: transparent;
      user-select: none;
    }
  }

  .category-checkbox {
    &:focus,
    &:not(:placeholder-shown) {
      & + .category-label {
        transform: translateY(0) scale(0.75);
        opacity: 0.75;
      }
    }
  }

  .category-label {
    ${props => (props.ko ? font.Noto700 : font.robotoMedium)};
    text-transform: uppercase;
    letter-spacing: 1px;
    color: ${color.grey};
    font-size: 1.4rem;
    margin-left: 16px;
    transition: all 0.15s ${ease.out};
    user-select: none;

    @media ${device.tabletP} {
      position: absolute;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .fs-checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: transparent;
    pointer-events: none;
    transition: all 0.15s ${ease.out};
  }

  .fs-checkmark:after {
    position: absolute;
    display: block;
    content: "";
    left: 4px;
    top: 2px;
    width: 7px;
    height: 12px;
    border: solid ${color.grey};
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    transition: all 0.15s ${ease.out};
  }

  .category-checkbox:checked ~ .fs-checkmark:after {
    border-color: ${color.red};
  }

  .category-checkbox:checked ~ .category-label {
    color: ${color.red};
  }

  .category-checkbox:hover:not(:checked) ~ .fs-checkmark:after {
    border-color: ${color.greyDarker};
  }

  .category-checkbox:hover:not(:checked) ~ .category-label {
    color: ${color.greyDarker};
  }

  .input-wrapper-message {
    position: relative;

    @media ${device.mobileP} {
      margin: 25px 10px;
    }

    @media ${device.tabletP} {
      margin: 25px 0 0;
    }
  }

  .fs-message {
    border: 0;
    border-bottom: 1px solid ${color.greyDark};
    padding: 15px 0px;
    outline: 0;
    width: 100%;
    position: relative;
    ${props => (props.ko ? font.Noto700 : font.robotoMedium)};
    letter-spacing: normal;
    color: ${color.greyDark};
    font-size: 1.4rem;
    min-height: 6rem;
    resize: vertical;

    &::placeholder {
      color: transparent;
      user-select: none;
    }
  }

  .fs-message ~ .category-label {
    left: 0;
    margin-left: 0;
    transform: translateY(1.5rem);
    transform-origin: left;
    color: ${color.greyDark};
  }

  .fs-message {
    &:focus,
    &:not(:placeholder-shown) {
      & + .category-label {
        transform: translateY(-0.5rem) scale(0.75);
        margin-left: 1px;
        opacity: 0.75;
      }
    }
  }

  .fs-input {
    width: 100%;
    border: 0;
    border-bottom: 1px solid ${color.greyDark};
    outline: 0;
    min-height: 44px;
    color: ${color.greyDark};
    ${props => (props.ko ? font.Noto700 : font.robotoMedium)};
    font-size: 1.4rem;
    letter-spacing: 0.8px;
    text-transform: uppercase;
    padding: 20px 12px 6px;
    margin-bottom: 25px;

    &::placeholder {
      color: transparent;
      user-select: none;
    }
  }

  .fs-input {
    &:focus,
    &:not(:placeholder-shown) {
      & + .fs-label {
        transform: translateY(-3px) scale(0.75);
        opacity: 0.75;
      }
    }
  }

  .fs-label {
    position: absolute;
    left: 12px;
    top: 6px;
    color: ${color.greyDark};
    ${props => (props.ko ? font.Noto700 : font.robotoMedium)};
    font-size: 14px;
    letter-spacing: 0.8px;
    text-transform: uppercase;
    transform-origin: left;
    transform: translateY(8px) scale(1);
    pointer-events: none;
    transition: all 0.15s ${ease.out};
  }

  .submit-wrapper {
    margin-top: 50px;
    display: flex;
    justify-content: center;
  }

  .file-upload {
    margin-bottom: 25px;

    @media ${device.tabletP} {
      display: grid;
      align-items: end;
      grid-template-columns: 40% 30%;
      grid-column-gap: 25px;
    }
  }

  #file-upload {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  #file-upload-readonly {
    border: 0;
    border-bottom: 1px solid ${color.greyDark};
    padding: 15px 0px 15px 12px;
    outline: 0;
    ${props => (props.ko ? font.Noto700 : font.robotoMedium)};
    letter-spacing: 0.8px;
    color: ${color.greyDark};
    font-size: 1.4rem;
    text-transform: uppercase;
    width: 100%;
    user-select: none;
    cursor: default;

    &::placeholder {
      padding: 6px 0px 0px 0px;
      color: ${color.greyDark};
      font-size: 1.4rem;
    }
  }

  #file-upload-label {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    outline: 0;
    min-height: 44px;
    height: 44px;
    width: auto;
    background-color: ${color.greyOpacity};
    color: ${color.greyDark};
    ${props => (props.ko ? font.Noto700 : font.robotoMedium)};
    font-size: 12px;
    letter-spacing: 0.8px;
    text-transform: uppercase;
    cursor: pointer;
  }

  #fs2-message,
  #fs3-message {
    padding-left: 12px;
    padding-right: 12px;
  }

  #fs2-message + .category-label,
  #fs3-message + .category-label {
    left: 12px;
  }

  .slick-track {
    display: flex;
    flex-direction: row;
  }

  .slick-list {
    width: 100%;
    overflow: hidden;

    @media ${device.mobileP} {
      max-width: 88vw;
    }

    @media ${device.mobileL} {
      max-width: 92vw;
    }

    @media ${device.tabletP} {
      max-width: 95vw;
    }
  }

  #secondary-choice {
    @media ${device.mobileP} {
      margin: 0 10px;
    }

    @media ${device.tabletP} {
      margin: 0;
    }
  }

  .message-label,
  .message-label2,
  .message-label3 {
    @media ${device.mobileP} {
      position: absolute;
    }
  }
`
