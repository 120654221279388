import React, { useEffect, useRef } from 'react'
import styled from "styled-components"
import Img from "gatsby-image"
import { font, color, device } from "../../layout/global"

import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

if (typeof window !== `undefined`) {
  gsap.registerPlugin(ScrollTrigger)
  gsap.core.globals("ScrollTrigger", ScrollTrigger)
}

const Introduction = ({className, id, info, ko}) => {

  const ref = useRef(null)
  const refH1 = useRef(null)
  const refH2 = useRef(null)
  const refP = useRef(null)
  const refImage = useRef(null)

  // ANIMATIONS
  useEffect(() => {
    
    gsap.fromTo(refH1.current, {opacity: 0, y: 5}, {opacity: 1, y: 0, duration: 0.7, ease: "power4.out",
      scrollTrigger: {
        trigger: ref.current,
        start: 'center 80%'
      }
    })
    
    gsap.fromTo([refH2.current, refP.current], {opacity: 0, y: 5}, {opacity: 1, y: 0, duration: 0.3,
      stagger: 0.15,
      scrollTrigger: {
        trigger: ref.current,
        start: '80% 80%'
      }
    })

    gsap.fromTo(refImage.current, {opacity: 0, scale: 0.7}, {opacity: 1, scale: 1, duration: 1.5, ease: "none", 
      scrollTrigger: {
        scrub: 1,
        trigger: ref.current,
        start: '35% 60%',
        end: '50% 60%'
      }
    })
    
  },[])


  return (
    <Wrapper id={id} className={className} ref={ref} ko={ko}>
      <h1 ref={refH1} dangerouslySetInnerHTML={{ __html: info.title }}></h1>
      <div className="image" ref={refImage}>
        <Img
          fluid={info.image.childImageSharp.fluid}
          alt={info.image.name}
          imgStyle={{ objectFit: "contain", objectPosition: "center center" }}
        />
      </div>
      <div className='bottom-container'>
      <h2 ref={refH2} dangerouslySetInnerHTML={{ __html: info.oven_title }}></h2>
      <p ref={refP} dangerouslySetInnerHTML={{ __html: info.oven_subtitle }}></p>
      </div>

    </Wrapper>
  )
}

export default Introduction

const Wrapper = styled.div`
  background-color: white;
  overflow: hidden;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  text-align: center;

  @media ${device.mobileP}{
    min-height:625px;
    flex-direction: column;
    justify-content: space-around;
    padding:0 25px;
  }

  @media ${device.desktop}{
    justify-content: center;
    align-content: center;
    height: 100vh;
    min-height:100vh;
    padding: 100px;
  }

  h1 {
    ${props => props.ko ? font.Noto400 : font.financierLight};
    opacity: 0.7;
    color: rgba(151,153,155,0.9);
    letter-spacing: ${props => props.ko ? '-3px' : '1px'};
    z-index: 0;

    @media ${device.mobileP}{
      font-size: ${props => props.ko ? '2.5rem' : '4rem'};
      line-height: 1.3;
      z-index: 1;
    }

    @media ${device.desktop}{
      font-size: ${props => props.ko ? '6rem' : '12.5rem'};
      line-height: 1;
      z-index: 0;
    }
  }
  h2 {
    ${props => props.ko ? font.Noto700 : font.robotoBold};
    color: ${color.red};
    text-transform: uppercase;
    letter-spacing: normal;

    @media ${device.mobileP}{
      font-size:2rem;
      line-height: 40px;
      margin-top:20px;
    }

    @media ${device.desktop}{
      font-size: 2.5rem;
    }
  }
  p {
    ${props => props.ko ? font.Noto700 : font.robotoMedium};
    color: ${color.greyDark};


    @media ${device.mobileP}{
      font-size:1.6rem;
    }

    @media ${device.desktop}{
      width: 650px;
      font-size: 2rem;
    }
  }

  .bottom-container{

    @media ${device.tabletP} {
      transform: translateY(50px);
    }
    @media ${device.desktop}{
      transform: unset;
    }
  }

  .submit-wrapper {
    margin-top: 50px;
    display: flex;
    justify-content: center;
  }

  .image {
    position:absolute;
    width:90vw;
    transform: translateY(15px);

    @media ${device.mobileP} {
      margin-top: 60px;
    }
    @media ${device.tabletP}{
      width: 60vw;
      margin-top: 150px;
    }

    @media ${device.desktop}{
      position: unset;
      transform: unset;
      width: 30vw;
      margin-top: ${props => props.ko ? '-1.5%' : '-0%'};
      margin-bottom: -7.5%;
    }
    @media (min-width: 1920px){
      position:unset;
      transform:unset;
      width: 30vw;
      margin-top: ${props => props.ko ? '-1.5%' : '-5%'};
      margin-bottom: -7.5%;
    }
  }

  @media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 2) {
    h1 {
      font-size: ${props => props.ko ? '6rem' : '10rem'};
    }

    .image {
      width: 40vw;
      margin-top: 0%;
      margin-bottom: ${props => props.ko && '-15%'};
    }
  }
`