import React, { useRef } from 'react'
import styled from 'styled-components'
import Img from "gatsby-image"
import { font, color } from '../../layout/global'
import Slider from "react-slick"

import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import LeftArrow from "../../images/Products/left-arrow.svg"
import RightArrow from "../../images/Products/right-arrow.svg"

if (typeof window !== `undefined`) {
  gsap.registerPlugin(ScrollTrigger)
  gsap.core.globals("ScrollTrigger", ScrollTrigger)
}

const FeatureLine = ({ id, marginLeft, positionCenter, whiteBg, info, ko}) => {

  const ref = useRef(null)

  function NextArrow(props){
    const { onClick, style } = props

    return(
      <>
        <div
          onClick={onClick}
          onKeyUp={onClick}
          role='button'
          tabIndex={0}
          className='next-arrow'
          style={{ ...style, 
            display:"block", 
            width:'15px',
            position: 'absolute',
            zIndex: '1',
            bottom: '26px',
            right: '15px',
            outline: '0'
          }}
        >
          <RightArrow />
        </div>
      </>
    )
  }

  function PrevArrow(props){
    const { onClick, style } = props

    return(
      <>
        <div
          onClick={onClick}
          onKeyUp={onClick}
          role='button'
          tabIndex={0}
          className='prev-arrow'
          style={{ ...style, 
            display:"block", 
            width:'15px',
            position: 'absolute',
            zIndex: '1',
            bottom: '26px',
            right: '115px',
            outline: '0'
          }}
        >
          <LeftArrow />
        </div>
      </>
    )
  }

  const sliderSettings = {
    dots: true,
    dotsClass: "pagination",
    customPaging: 
      function (i) {
        return (
          <h1>0{i + 1}</h1>
        )
      },
    infinite: false,
    slidesToShow: 1,
    pauseOnHover: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  }
  
  return (
    <Wrapper marginLeft={marginLeft} positionCenter={positionCenter} whiteBg={whiteBg} id={id} ref={ref}>
      <Slider {...sliderSettings}>
        {info.map((i) => 
          <div className="feat-image" key={i}>
            <Img
              fluid={i.image.childImageSharp.fluid}
              alt={i.image.name}
              imgStyle={{ objectFit: "contain", objectPosition: "center center" }}
            />
          </div>
        )}
      </Slider>
      <h3> /06</h3>
    </Wrapper>
  )
}

export default FeatureLine

const Wrapper = styled.section`
  position: relative;
  background-color: ${color.black};
  width: 100%;
  opacity: 1;
  overflow: hidden;
  padding:30px 0;
  display:flex;
  flex-direction:column;
  justify-content:center;
  min-height:100vh;

  .slick-slider{
    height: 75vh;

    .slick-track{
      display:flex;
      flex-direction:row;
    }

    svg{
      fill: #fff;
    }
  }

  .pagination{
    position: absolute;
    right: 60px;
    bottom: 20px;

    li{
      display:none;
      pointer-events:none;
    }

    .slick-active{
      display:block;

      h1{
        ${props => props.ko ? font.Noto700 : font.roboto400};
        color:#fff;
        font-size:4rem;
        line-height:0.9;
      }
    }
  }

  h3{
    position:absolute;
    right:40px;
    bottom:20px;
    ${props => props.ko ? font.Noto700 : font.roboto400};
    color:#fff;
  }
`